import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import Players from './components/Players/Players';
import SliderComponent from './components/Slider/Slider';
import Streamers from './components/Streamers/Streamers'
import TeamSection from './components/TeamSection/TeamSection';
import SteamFinder from './components/SteamFinder/SteamFinder';
import Footer from './components/Footer/Footer';
const App = () => {
  return (
    <div>
      <Header />
      <Navbar />
      <Players />
      <SliderComponent />
      <Streamers />
      <TeamSection />
      <SteamFinder />
      <Footer />
    </div>
  );
};

export default App;
