import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Players.css'; // Ensure this file contains your CSS styles

const Players = () => {
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get('https://pprp.vercel.app/api/stats/'); // Update to your backend URL
                setStats(response.data);
                setError(null); // Clear previous error if successful
            } catch (err) {
                console.error('Error fetching statistics:', err.message);
                setError('Failed to fetch data. Please try again later.');
                // Set stats to default values
                setStats({
                    serverName: 'N/A',
                    serverStatus: 'N/A',
                    totalPlayers: 'N/A',
                    currentPlayers: 'N/A',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchStats();

        // Polling every 30 seconds
        const interval = setInterval(fetchStats, 30000);
        return () => clearInterval(interval);
    }, []);

    if (loading) return <div>Loading...</div>;

    return (
        <div className="players-container">
            <h1 className="lilita-one-regular">Server Stats</h1>
            <div className="lilita-one-regular players-stats">
                <div className="stat-box">
                    <h3>Name</h3>
                    <p>{stats?.serverName || 'N/A'}</p>
                </div>
                <div className="stat-box">
                    <h3>Status</h3>
                    <p>{stats?.serverStatus || 'N/A'}</p>
                </div>
                <div className="stat-box">
                    <h3>Max Players</h3>
                    <p>{stats?.totalPlayers || 'N/A'}</p>
                </div>
                <div className="stat-box">
                    <h3>Current Players</h3>
                    <p>{stats?.currentPlayers || 'N/A'}</p>
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default Players;
