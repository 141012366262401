import React from 'react';
import './Footer.css';
import '../Header/Header.css'
const Footer = () => {
  return (
    <footer className="footer">
      <p className="copyright josefin-sans">
      Copyright © PPRP. | All rights reserved 2019 - 2024 | Terms of Service
      </p>
      <p className="josefin-sans credit">Credit: Kuong</p>
    </footer>
  );
};

export default Footer;
