import React, { useState } from 'react';
import './Navbar.css';
import img from '../../pprp.png';
import { FaSteam, FaDiscord, FaFacebook, FaKeyboard, FaBook } from "react-icons/fa"; // Import additional icons
import { SiGoogleforms } from "react-icons/si";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar" id='#Home'>
      <div className="left-section">
        <img className="pprp-logo" src={img} alt="PPRP" />
        <a className="logo bebas-neue-regular" href='#Home'>Phnom Penh RolePlay</a>
      </div>
      <div
        className="burger"
        onClick={toggleMenu}
        aria-expanded={isMenuOpen ? "true" : "false"}
      >
        <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
      </div>
      <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <li>
          <a
            href="https://discord.gg/qqAs5s2U9C"
            target="_blank"
            rel="noopener noreferrer"
            className='a1 lilita-one-regular'
          >
            <FaDiscord /> Discord
          </a>
        </li>
        <li>
          <a
            href="https://forms.gle/HnYvYYnrK5tnNnow6"
            target="_blank"
            rel="noopener noreferrer"
            className='a1 lilita-one-regular'
          >
            <SiGoogleforms /> Register
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/pprpcity"
            target="_blank"
            rel="noopener noreferrer"
            className='a1 lilita-one-regular'
          >
            <FaFacebook /> Facebook Page
          </a>
        </li>
        <li>
          <a 
              href="#SteamFinder"
              className='a1 lilita-one-regular'
          >
            <FaSteam /> SteamHex Finder
          </a>
        </li>
        <li>
          <a 
              href="#keys"
              className='a1 lilita-one-regular'
          >
            <FaKeyboard /> Keys & Command
          </a>
        </li>
        <li>
          <a
            href="https://docs.google.com/document/d/10RL0jAFwEFWj_j4Q2Ro4CeZsDFqs9AZuwYx_v3y6NX0/edit?tab=t.0"
            target="_blank"
            rel="noopener noreferrer"
            className='a1 lilita-one-regular'
          >
            <FaBook /> Rules
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
