import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Streamers.css';
import { FaFacebook, FaYoutube } from "react-icons/fa";
const Streamers = () => {
    const [streamers, setStreamers] = useState([]);
    const [selectedStreamer, setSelectedStreamer] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Fetch streamers from the backend
    useEffect(() => {
        const fetchStreamers = async () => {
            try {
                const response = await fetch('https://pprp.vercel.app/asset/streamers'); // Backend URL
                const data = await response.json();
                setStreamers(data);
            } catch (error) {
                console.error('Error fetching streamers:', error.message);
            }
        };

        fetchStreamers();
    }, []);

    // Modal open handler
    const openModal = (streamer) => {
        setSelectedStreamer(streamer); // Set the clicked streamer
        setIsModalOpen(true); // Open the modal
    };

    // Modal close handler
    const closeModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedStreamer(null); // Clear the selected streamer
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: 2 },
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 1 },
            },
        ],
    };

    return (
        <div className="streamers-container">
            <h1 className="streamers-title">Our Streamers</h1>
            <Slider {...settings}>
                {streamers.map((streamer) => (
                    <div
                        key={streamer.id}
                        className="streamer-card"
                        onClick={() => openModal(streamer)} // Open modal on card click
                    >
                        <div className="streamer-banner">STREAMER</div>
                        <img
                            src={`https://pprp.vercel.app${streamer.image}`}
                            alt={`Profile of ${streamer.name}`}
                            className="streamer-image"
                        />
                        <div className="streamer-info">
                            <h2>{streamer.name}</h2>
                            <p>{streamer.title}</p>
                        </div>
                    </div>
                ))}
            </Slider>

            {/* Modal */}
            {isModalOpen && selectedStreamer && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="modal-close" onClick={closeModal}>
                            &times;
                        </button>
                        <div className="modal-header">
                            <img
                                src={`https://pprp.vercel.app${selectedStreamer.image}`}
                                alt={`Profile of ${selectedStreamer.name}`}
                                className="modal-image"
                            />
                            <h2>{selectedStreamer.name}</h2>
                        </div>
                        <div className="modal-body">
                            <p>{selectedStreamer.title}</p>
                            {selectedStreamer.facebook && (
                                <p>
                                    <a
                                        href={selectedStreamer.facebook}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebook /> Visit Facebook
                                    </a>
                                </p>
                            )}
                            {selectedStreamer.youtube && (
                                <p>
                                    <a
                                        href={selectedStreamer.youtube}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaYoutube /> Visit YouTube
                                    </a>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Streamers;
