import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className='heads'>
        <h3 className='h3-text josefin-sans'>
          PPRP IS ONE OF THE BEST SERVER IN CAMBODIA SINCE 2019
          . #FOR KHMER BY KHMER</h3>
    </div>
  );
};

export default Header;
