import React, { useState } from "react";
import "./SteamFinder.css";
import JSBI from "jsbi";
import { FaSteam } from "react-icons/fa";
const SteamFinder = () => {
    const [inputValue, setInputValue] = useState("");
    const [steam64, setSteam64] = useState("");
    const [steamHex, setSteamHex] = useState("");
    const [error, setError] = useState("");

    const handleResolveSteamId = async () => {
        setError("");
        setSteam64("");
        setSteamHex("");
    
        try {
            if (inputValue.includes("/id/")) {
                const vanityUrl = inputValue.split("/id/")[1].split("/")[0];
                const response = await fetch(`https://pprp.vercel.app/api/resolveSteamId?vanityUrl=${vanityUrl}`);
                const data = await response.json();
    
                if (data.error) {
                    setError(data.error);
                    return;
                }
    
                const resolvedSteam64 = data.steamId;
                setSteam64(resolvedSteam64);
    
                // Use JSBI for hex conversion
                const resolvedSteamHex = "steam:" + JSBI.BigInt(resolvedSteam64).toString(16);
                setSteamHex(resolvedSteamHex);
            } else if (inputValue.includes("/profiles/")) {
                const id = inputValue.split("/profiles/")[1].split("/")[0];
                setSteam64(id);
    
                // Use JSBI for hex conversion
                const resolvedSteamHex = "steam:" + JSBI.BigInt(id).toString(16);
                setSteamHex(resolvedSteamHex);
            } else {
                setError("Invalid Steam profile URL. It must include '/profiles/' or '/id/'.");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            console.error("Error resolving Steam ID:", error);
        }
    };

    return (
        <div id="SteamFinder" className="steam-finder-container">
            <h1 className="steam-ico"><FaSteam /> Steam ID Finder</h1>
            <p>Enter your Steam profile URL to find the Steam64 ID and Steam Hex.</p>
            <input
                type="text"
                className="steam-input"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter Steam profile URL"
            />
            <button className="resolve-button" onClick={handleResolveSteamId}>
                Confirm
            </button>
            {error && <div className="error-message">{error}</div>}
            {steam64 && (
                <div className="results">
                    <p>Steam64 ID: {steam64}</p>
                    <p>Steam Hex: {steamHex}</p>
                </div>
            )}
        </div>
    );
};

export default SteamFinder;
