import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Slider.css';

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img src="/assets/slider/01.png" alt="Image 1" />
        </div>
        <div>
          <img src="/assets/slider/02.png" alt="Image 2" />
        </div>
        <div>
          <img src="/assets/slider/03.png" alt="Image 3" />
        </div>
        <div>
          <img src="/assets/slider/04.png" alt="Image 4" />
        </div>
        <div>
          <img src="/assets/slider/05.png" alt="Image 5" />
        </div>
        <div>
          <img src="/assets/slider/06.png" alt="Image 6" />
        </div>
        <div>
          <img src="/assets/slider/07.png" alt="Image 7" />
        </div>
        <div>
          <img src="/assets/slider/08.png" alt="Image 8" />
        </div>
      </Slider>
    </div>
  );
};

export default SliderComponent;
