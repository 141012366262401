import React, { useState } from 'react';
import './TeamSection.css';
import ourTeamImage from '../../ourteam.png';

const TeamSection = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const handleCardClick = (member) => {
    if (member.details) {
      setSelectedMember(member);
    }
  };

  const closeModal = () => {
    setSelectedMember(null);
  };
  const team = [
    { name: "Ream Angkor", ign: "Than Hong &Ream Angkor", role: "Founder", img: "/assets/team/ream.jpg", details: "Seihaream 'Ream Angkor' Dyna (born January 16, 1991) is a Cambodian professional PUBG Mobile caster, analyst, content creator, streamer, and Esport enthusiast."  },
    { name: "Nan", ign: "Ma Kao", role: "Head of Admin", img: "/assets/team/nan.jpg", details: null },
    { name: "XyRus", ign: "Bun Cheoun & Min Songha", role: "Admin", img: "/assets/team/xyrus.jpg", details: null },
    { name: "Sur Sdey", ign: "SUR MINSDEY", role: "Admin & Dev", img: "/assets/team/sursdey.jpg", details: null },
    { name: "Chan Aza", ign: "Jook Ma", role: "Admin", img: "/assets/team/chanaza.jpg", details: null },
    { name: "Kuong", ign: "ImUrDADDY", role: "Head of Developer", img: "/assets/team/kuong.png", details: null },
    { name: "Mean Devith", ign: "Goo Vannda", role: "Mod & Graphic Designer", img: "/assets/team/meandevith.jpg", details: null },
    { name: "LengZ", ign: "ChetZu", role: "Mod & MLO Designer", img: "/assets/team/lengz.png", details: null },
    { name: "Vireak Sokha", ign: "Ching JR", role: "Moderator", img: "/assets/team/chhing.jpg", details: null },
    { name: "Euk SoPhea", ign: "So Phea", role: "Support Team", img: "/assets/team/phea.jpg", details: null },
    { name: "Kuong CheakLeng", ign: "Cheak Leng", role: "Moderator", img: "/assets/team/cheakleng.jpg", details: null },
    { name: "Pu Ya", ign: "Pu Ya", role: "Moderator", img: "/assets/team/puya.jpg", details: null },
    { name: "Vit", ign: "PAO VIT", role: "Graphic Designer", img: "/assets/team/vit.jpg", details: null },
  ];

  return (
    <div className="team-section">
      <div
        className="logo-team"
        style={{ backgroundImage: `url(${ourTeamImage})` }}
      ></div>
      <div className="team-grid">
        {team.map((member, index) => (
          <div
            key={index}
            className="team-card"
            onClick={() => handleCardClick(member)}
          >
            <img src={member.img} alt={member.name} />
            <h3 className='roboto-bold'>{member.name}</h3>
            <h4 className='roboto-medium'>{member.ign}</h4>
            <p className='roboto-light'>{member.role}</p>
          </div>
        ))}
      </div>

      {selectedMember && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <img src={selectedMember.img} alt={selectedMember.name} />
            <h3>{selectedMember.name}</h3>
            <h4>{selectedMember.ign}</h4>
            <p>{selectedMember.role}</p>
            <p>{selectedMember.details}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamSection;